<section class="ticket-price" id="ticket">
  <div class="ticket-price__container container">
    <h2 class="ticket-price__title">
      Купить билет
    </h2>
    <button type="button" id="pirexpo-ticket-widget" data-pirexpo-widget-shop-id="2" class="ticket-price__buy-btn button button--lime">
      <span class="text"><span class="desktop">УСПЕЙ</span> КУПИТЬ БИЛЕТ</span>
    </button>
    <div class="ticket-price__prices">
      <div class="ticket-price__block-container" *ngFor="let price of prices; index as i">
        <app-ticket-price-block
          class="ticket-price__block"
          [text]="price.date"
          [price]="price.price"
          [active]="i === 3"
          [old]="i < 3"
        ></app-ticket-price-block>
      </div>
    </div>
    <h2 class="ticket-price__title ticket-price__title--center">Что входит в билет:</h2>
    <ul class="ticket-price__list">
      <li>
        Более 50 мастер-классов и семинаров
      </li><li>
        Опыт, знания и рецепты от 40 ведущих экспертов
      </li><li>
        Бизнес-разборы по управлению производством

      </li><li>
        Маркет инвентаря и ингредиентов
      </li><li>
        Деловое и неформальное общение кондитеров и пекарей
      </li><li>
        И внимание! Сюрпризы и розыгрыши подарков от партнёров и экспертов проекта.
      </li>
    </ul>
    <!-- <button type="button" id="timepad_twf_register_2272144" class="ticket-price__buy-btn button">УСПЕЙ КУПИТЬ БИЛЕТ</button> -->
  </div>
</section>
