/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Partner } from '../models/partner';
import { PartnerDetail } from '../models/partner-detail';
@Injectable({
  providedIn: 'root',
})
class PartnerService extends __BaseService {
  static readonly partnerListListPath = '/partner/list';
  static readonly partnerListCreatePath = '/partner/list';
  static readonly partnerReadPath = '/partner/{id}';
  static readonly partnerUpdatePath = '/partner/{id}';
  static readonly partnerPartialUpdatePath = '/partner/{id}';
  static readonly partnerDeletePath = '/partner/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `PartnerService.PartnerListListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  partnerListListResponse(params: PartnerService.PartnerListListParams): __Observable<__StrictHttpResponse<Array<Partner>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/partner/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Partner>>;
      })
    );
  }
  /**
   * @param params The `PartnerService.PartnerListListParams` containing the following parameters:
   *
   * - `type`: type
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  partnerListList(params: PartnerService.PartnerListListParams): __Observable<Array<Partner>> {
    return this.partnerListListResponse(params).pipe(
      __map(_r => _r.body as Array<Partner>)
    );
  }

  /**
   * @param data undefined
   */
  partnerListCreateResponse(data: Partner): __Observable<__StrictHttpResponse<Partner>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/partner/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Partner>;
      })
    );
  }
  /**
   * @param data undefined
   */
  partnerListCreate(data: Partner): __Observable<Partner> {
    return this.partnerListCreateResponse(data).pipe(
      __map(_r => _r.body as Partner)
    );
  }

  /**
   * @param id undefined
   */
  partnerReadResponse(id: string): __Observable<__StrictHttpResponse<PartnerDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/partner/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerDetail>;
      })
    );
  }
  /**
   * @param id undefined
   */
  partnerRead(id: string): __Observable<PartnerDetail> {
    return this.partnerReadResponse(id).pipe(
      __map(_r => _r.body as PartnerDetail)
    );
  }

  /**
   * @param params The `PartnerService.PartnerUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  partnerUpdateResponse(params: PartnerService.PartnerUpdateParams): __Observable<__StrictHttpResponse<PartnerDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/partner/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerDetail>;
      })
    );
  }
  /**
   * @param params The `PartnerService.PartnerUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  partnerUpdate(params: PartnerService.PartnerUpdateParams): __Observable<PartnerDetail> {
    return this.partnerUpdateResponse(params).pipe(
      __map(_r => _r.body as PartnerDetail)
    );
  }

  /**
   * @param params The `PartnerService.PartnerPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  partnerPartialUpdateResponse(params: PartnerService.PartnerPartialUpdateParams): __Observable<__StrictHttpResponse<PartnerDetail>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/partner/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PartnerDetail>;
      })
    );
  }
  /**
   * @param params The `PartnerService.PartnerPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  partnerPartialUpdate(params: PartnerService.PartnerPartialUpdateParams): __Observable<PartnerDetail> {
    return this.partnerPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as PartnerDetail)
    );
  }

  /**
   * @param id undefined
   */
  partnerDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/partner/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  partnerDelete(id: string): __Observable<null> {
    return this.partnerDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PartnerService {

  /**
   * Parameters for partnerListList
   */
  export interface PartnerListListParams {

    /**
     * type
     */
    type?: string;

    /**
     * theme
     */
    theme?: string;

    /**
     * project
     */
    project?: string;

    /**
     * Ordering
     */
    ordering?: 'order' | '-order' | 'name' | '-name';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for partnerUpdate
   */
  export interface PartnerUpdateParams {
    id: string;
    data: PartnerDetail;
  }

  /**
   * Parameters for partnerPartialUpdate
   */
  export interface PartnerPartialUpdateParams {
    id: string;
    data: PartnerDetail;
  }
}

export { PartnerService }
