/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Project } from '../models/project';
@Injectable({
  providedIn: 'root',
})
class ProjectService extends __BaseService {
  static readonly projectListListPath = '/project/list';
  static readonly projectListCreatePath = '/project/list';
  static readonly projectReadPath = '/project/{id}';
  static readonly projectUpdatePath = '/project/{id}';
  static readonly projectPartialUpdatePath = '/project/{id}';
  static readonly projectDeletePath = '/project/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProjectService.ProjectListListParams` containing the following parameters:
   *
   * - `status`: статус публикации проекта
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  projectListListResponse(params: ProjectService.ProjectListListParams): __Observable<__StrictHttpResponse<Array<Project>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.status != null) __params = __params.set('status', params.status.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Project>>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectListListParams` containing the following parameters:
   *
   * - `status`: статус публикации проекта
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  projectListList(params: ProjectService.ProjectListListParams): __Observable<Array<Project>> {
    return this.projectListListResponse(params).pipe(
      __map(_r => _r.body as Array<Project>)
    );
  }

  /**
   * @param data undefined
   */
  projectListCreateResponse(data: Project): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/project/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param data undefined
   */
  projectListCreate(data: Project): __Observable<Project> {
    return this.projectListCreateResponse(data).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param id A unique integer value identifying this project.
   */
  projectReadResponse(id: number): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/project/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this project.
   */
  projectRead(id: number): __Observable<Project> {
    return this.projectReadResponse(id).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param params The `ProjectService.ProjectUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectUpdateResponse(params: ProjectService.ProjectUpdateParams): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/project/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectUpdate(params: ProjectService.ProjectUpdateParams): __Observable<Project> {
    return this.projectUpdateResponse(params).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param params The `ProjectService.ProjectPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectPartialUpdateResponse(params: ProjectService.ProjectPartialUpdateParams): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/project/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * @param params The `ProjectService.ProjectPartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this project.
   *
   * - `data`:
   */
  projectPartialUpdate(params: ProjectService.ProjectPartialUpdateParams): __Observable<Project> {
    return this.projectPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * @param id A unique integer value identifying this project.
   */
  projectDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/project/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this project.
   */
  projectDelete(id: number): __Observable<null> {
    return this.projectDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ProjectService {

  /**
   * Parameters for projectListList
   */
  export interface ProjectListListParams {

    /**
     * статус публикации проекта
     */
    status?: 'draft' | 'active' | 'archive';

    /**
     * Ordering
     */
    ordering?: 'date_start' | '-date_start' | 'name' | '-name';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for projectUpdate
   */
  export interface ProjectUpdateParams {

    /**
     * A unique integer value identifying this project.
     */
    id: number;
    data: Project;
  }

  /**
   * Parameters for projectPartialUpdate
   */
  export interface ProjectPartialUpdateParams {

    /**
     * A unique integer value identifying this project.
     */
    id: number;
    data: Project;
  }
}

export { ProjectService }
