/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ReleaseFilter } from '../models/release-filter';
import { Release } from '../models/release';
@Injectable({
  providedIn: 'root',
})
class ReleaseService extends __BaseService {
  static readonly releaseFilterListPath = '/release/filter';
  static readonly releaseListListPath = '/release/list';
  static readonly releaseListCreatePath = '/release/list';
  static readonly releaseReadPath = '/release/{id}';
  static readonly releaseUpdatePath = '/release/{id}';
  static readonly releasePartialUpdatePath = '/release/{id}';
  static readonly releaseDeletePath = '/release/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ReleaseService.ReleaseFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `project`: project
   *
   * - `ids`: ids
   */
  releaseFilterListResponse(params: ReleaseService.ReleaseFilterListParams): __Observable<__StrictHttpResponse<ReleaseFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/release/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ReleaseFilter>;
      })
    );
  }
  /**
   * @param params The `ReleaseService.ReleaseFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `project`: project
   *
   * - `ids`: ids
   */
  releaseFilterList(params: ReleaseService.ReleaseFilterListParams): __Observable<ReleaseFilter> {
    return this.releaseFilterListResponse(params).pipe(
      __map(_r => _r.body as ReleaseFilter)
    );
  }

  /**
   * @param params The `ReleaseService.ReleaseListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `project`: project
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  releaseListListResponse(params: ReleaseService.ReleaseListListParams): __Observable<__StrictHttpResponse<Array<Release>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.sort != null) __params = __params.set('sort', params.sort.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/release/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Release>>;
      })
    );
  }
  /**
   * @param params The `ReleaseService.ReleaseListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `sort`: Ordering
   *
   * - `project`: project
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  releaseListList(params: ReleaseService.ReleaseListListParams): __Observable<Array<Release>> {
    return this.releaseListListResponse(params).pipe(
      __map(_r => _r.body as Array<Release>)
    );
  }

  /**
   * @param data undefined
   */
  releaseListCreateResponse(data: Release): __Observable<__StrictHttpResponse<Release>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/release/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Release>;
      })
    );
  }
  /**
   * @param data undefined
   */
  releaseListCreate(data: Release): __Observable<Release> {
    return this.releaseListCreateResponse(data).pipe(
      __map(_r => _r.body as Release)
    );
  }

  /**
   * @param id undefined
   */
  releaseReadResponse(id: string): __Observable<__StrictHttpResponse<Release>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/release/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Release>;
      })
    );
  }
  /**
   * @param id undefined
   */
  releaseRead(id: string): __Observable<Release> {
    return this.releaseReadResponse(id).pipe(
      __map(_r => _r.body as Release)
    );
  }

  /**
   * @param params The `ReleaseService.ReleaseUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  releaseUpdateResponse(params: ReleaseService.ReleaseUpdateParams): __Observable<__StrictHttpResponse<Release>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/release/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Release>;
      })
    );
  }
  /**
   * @param params The `ReleaseService.ReleaseUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  releaseUpdate(params: ReleaseService.ReleaseUpdateParams): __Observable<Release> {
    return this.releaseUpdateResponse(params).pipe(
      __map(_r => _r.body as Release)
    );
  }

  /**
   * @param params The `ReleaseService.ReleasePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  releasePartialUpdateResponse(params: ReleaseService.ReleasePartialUpdateParams): __Observable<__StrictHttpResponse<Release>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/release/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Release>;
      })
    );
  }
  /**
   * @param params The `ReleaseService.ReleasePartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  releasePartialUpdate(params: ReleaseService.ReleasePartialUpdateParams): __Observable<Release> {
    return this.releasePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Release)
    );
  }

  /**
   * @param id undefined
   */
  releaseDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/release/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  releaseDelete(id: string): __Observable<null> {
    return this.releaseDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ReleaseService {

  /**
   * Parameters for releaseFilterList
   */
  export interface ReleaseFilterListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at';

    /**
     * project
     */
    project?: string;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for releaseListList
   */
  export interface ReleaseListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * Ordering
     */
    sort?: 'name' | '-name' | 'created_at' | '-created_at' | 'updated_at' | '-updated_at';

    /**
     * project
     */
    project?: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for releaseUpdate
   */
  export interface ReleaseUpdateParams {
    id: string;
    data: Release;
  }

  /**
   * Parameters for releasePartialUpdate
   */
  export interface ReleasePartialUpdateParams {
    id: string;
    data: Release;
  }
}

export { ReleaseService }
