/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NewsFilter } from '../models/news-filter';
import { News } from '../models/news';
import { NewsCreate } from '../models/news-create';
@Injectable({
  providedIn: 'root',
})
class NewsService extends __BaseService {
  static readonly newsFilterListPath = '/news/filter';
  static readonly newsListListPath = '/news/list';
  static readonly newsListCreatePath = '/news/list';
  static readonly newsReadPath = '/news/{id}';
  static readonly newsUpdatePath = '/news/{id}';
  static readonly newsPartialUpdatePath = '/news/{id}';
  static readonly newsDeletePath = '/news/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `NewsService.NewsFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `ids`: ids
   */
  newsFilterListResponse(params: NewsService.NewsFilterListParams): __Observable<__StrictHttpResponse<NewsFilter>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/news/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsFilter>;
      })
    );
  }
  /**
   * @param params The `NewsService.NewsFilterListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `ids`: ids
   */
  newsFilterList(params: NewsService.NewsFilterListParams): __Observable<NewsFilter> {
    return this.newsFilterListResponse(params).pipe(
      __map(_r => _r.body as NewsFilter)
    );
  }

  /**
   * @param params The `NewsService.NewsListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  newsListListResponse(params: NewsService.NewsListListParams): __Observable<__StrictHttpResponse<Array<News>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.theme != null) __params = __params.set('theme', params.theme.toString());
    if (params.project != null) __params = __params.set('project', params.project.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/news/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<News>>;
      })
    );
  }
  /**
   * @param params The `NewsService.NewsListListParams` containing the following parameters:
   *
   * - `theme`: theme
   *
   * - `project`: project
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `ids`: ids
   */
  newsListList(params: NewsService.NewsListListParams): __Observable<Array<News>> {
    return this.newsListListResponse(params).pipe(
      __map(_r => _r.body as Array<News>)
    );
  }

  /**
   * @param data undefined
   */
  newsListCreateResponse(data: NewsCreate): __Observable<__StrictHttpResponse<NewsCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/news/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsCreate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  newsListCreate(data: NewsCreate): __Observable<NewsCreate> {
    return this.newsListCreateResponse(data).pipe(
      __map(_r => _r.body as NewsCreate)
    );
  }

  /**
   * @param id undefined
   */
  newsReadResponse(id: string): __Observable<__StrictHttpResponse<News>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/news/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<News>;
      })
    );
  }
  /**
   * @param id undefined
   */
  newsRead(id: string): __Observable<News> {
    return this.newsReadResponse(id).pipe(
      __map(_r => _r.body as News)
    );
  }

  /**
   * @param params The `NewsService.NewsUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  newsUpdateResponse(params: NewsService.NewsUpdateParams): __Observable<__StrictHttpResponse<NewsCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/news/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsCreate>;
      })
    );
  }
  /**
   * @param params The `NewsService.NewsUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  newsUpdate(params: NewsService.NewsUpdateParams): __Observable<NewsCreate> {
    return this.newsUpdateResponse(params).pipe(
      __map(_r => _r.body as NewsCreate)
    );
  }

  /**
   * @param params The `NewsService.NewsPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  newsPartialUpdateResponse(params: NewsService.NewsPartialUpdateParams): __Observable<__StrictHttpResponse<NewsCreate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/news/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NewsCreate>;
      })
    );
  }
  /**
   * @param params The `NewsService.NewsPartialUpdateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  newsPartialUpdate(params: NewsService.NewsPartialUpdateParams): __Observable<NewsCreate> {
    return this.newsPartialUpdateResponse(params).pipe(
      __map(_r => _r.body as NewsCreate)
    );
  }

  /**
   * @param id undefined
   */
  newsDeleteResponse(id: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/news/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id undefined
   */
  newsDelete(id: string): __Observable<null> {
    return this.newsDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module NewsService {

  /**
   * Parameters for newsFilterList
   */
  export interface NewsFilterListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * project
     */
    project?: string;

    /**
     * Ordering
     */
    ordering?: 'created_at' | '-created_at';

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for newsListList
   */
  export interface NewsListListParams {

    /**
     * theme
     */
    theme?: string;

    /**
     * project
     */
    project?: string;

    /**
     * Ordering
     */
    ordering?: 'created_at' | '-created_at';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * ids
     */
    ids?: string;
  }

  /**
   * Parameters for newsUpdate
   */
  export interface NewsUpdateParams {
    id: string;
    data: NewsCreate;
  }

  /**
   * Parameters for newsPartialUpdate
   */
  export interface NewsPartialUpdateParams {
    id: string;
    data: NewsCreate;
  }
}

export { NewsService }
